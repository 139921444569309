
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { fetchAPI, fetchMenu } from "../lib/api";
import { GetStaticProps } from "next";
import Layout from "../components/layout/layout";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import { Logo } from "components/Logo/Logo";
import { mq } from "styles/styles";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";

const page404 = ({ title, credits, menu }) => {
  const { t } = useTranslation("common");

  return (
    <Layout title={t`errors:Page Not Found`}>
      <div className={"background-overlay"}></div>
      <div className={"content-wrapper"}>
        <div className={"content"}>
          <div className="LanguageSwitcher-holder">
            <LanguageSwitcher />
          </div>
          <div>
            <Logo />
            <h1 className="title">{t`errors:Page Not found`}</h1>
            <p>{t`errors:404About`}</p>
            <Link href="/">
              <a className="back-link">{t`errors:Back to home`}</a>
            </Link>
          </div>
          <div>
            <p
              className="credits"
              dangerouslySetInnerHTML={{ __html: credits }}
            />
          </div>
        </div>
      </div>
      <style jsx>{`
        .background-overlay {
          display: block;
          position: fixed;
          z-index: 0;
          width: 100vw;
          height: 100vh;
          top: 0;
          left: 0;
          background: white;
        }

        .content-wrapper {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }

        .content {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          z-index: 10;
          position: relative;
          text-align: center;
          padding: 2rem;
        }

        .LanguageSwitcher-holder {
          position: absolute;
          top: var(--spacing);
          right: var(--spacing);
        }
        @media ${mq.mediumUp} {
          .LanguageSwitcher-holder {
            top: var(--spacing-2x);
            right: var(--spacing-2x);
          }
        }

        .title {
          font: var(--font-heading-1);
          text-align: center;
          padding-top: 10rem;
        }
        @media ${mq.mediumUp} {
          .title {
            padding-top: 25rem;
          }
        }

        .back-link {
          padding-bottom: var(--spacing);

          font: var(--font-heading-4);
          text-transform: uppercase;
          text-decoration: underline;
        }

        .back-link:hover {
          color: var(--purple);
        }

        .credits {
          font: var(--font-caption);
          width: 100%;
          margin: 0;
          text-align: center;
        }
      `}</style>
    </Layout>
  );
};

 const _getStaticProps: GetStaticProps = async ({ locale }) => {
  const content = await fetchAPI(locale, "page('home')", {
    title: true,
    maincredit: true,
    credits: true,
    intro: "page.intro.kirbytext",
  });

  const menu = await fetchMenu(locale);

  return {
    props: {
      locale,
      menu,
      ...content,
    },
  };
};
export default page404;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  